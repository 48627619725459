import { FC, useEffect } from "react";
import { useRouter } from "next/router";
import Dialog, {
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@/components/Dialog";
import Button from "@/components/Button";
import Typography from "@/components/Typography";
import { SubmissionTypeItem } from "@/fetch/rewards";
import styles from "./LockedDialog.module.scss";
import { useTrackers } from "@/hooks";

type Props = {};

const LockedDialog: FC<Props> = ({}) => {
  const { push } = useRouter();
  const { track } = useTrackers();

  useEffect(() => {
    track("Deal Locked Dialog Opened", {
      eventId: "deal-locked-dialog-opened",
    });
  }, []);

  const onPay = () => {
    track("Deal Locked Dialog Payment Initiated", {
      eventId: "deal-locked-dialog-payment-initiated",
    });
    push("/experience/payments/invoice/trip");
  };

  const handleClose = () => {
    track("Deal Locked Dialog Left", {
      eventId: "deal-locked-dialog-left",
    });
    push("/marketplace");
  };

  return (
    <Dialog open>
      <DialogContent className={styles.dialogContent}>
        <Typography variant="h6">Unlock hundreds of deals!</Typography>
        <Typography
          variant="subtitle1"
          color="text.secondary"
          className={styles.description}
        >
          Make a payment towards your trip to gain exclusive access to the
          Global Marketplace! Explore our catalogue of deals from travel-related
          brands, featuring discounts on travel gear, tours, tickets & more!
        </Typography>
      </DialogContent>
      <DialogActions className={styles.actionContainer}>
        <Button
          onClick={handleClose}
          variant="outlined"
          color="info"
          size="small"
          className={styles.cancelButton}
        >
          I&apos;m not ready yet
        </Button>
        <Button
          onClick={onPay}
          variant="contained"
          color="primary"
          size="small"
        >
          Unlock deals
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LockedDialog;
