import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

export type Item = {
  id: number;
  title: string;
  short_description: string;
  category_id: number;
  expiry_date: number;
  long_description: string;
  link: string;
  brand_id: number;
  _ca: number;
  _cb: number;
  _lma: number;
  _lmb: number;
  amount: number;
  currency: null;
  type_percentage: number;
  status: number;
  physical_good: null;
  is_upto: number;
  category_name: string;
  category_color: null;
  brand_name: string;
  brand_image: string;
  brand_location: string;
  office: null;
  category_icon: string;
};

type Result = {
  response: {
    results: Array<Item>;
    totalCount: number;
  };
};

type QueryError = {};

export enum SortValues {
  NewestDeals = "newestDeals",
  OldestDeals = "oldestDeals",
  BrandAZ = "brandAZ",
  BrandZA = "brandZA",
  Popularity = "popularity",
}

type Variables = {
  pageSize: number;
  pageNum: number;
  region?: string;
  categoryIds?: Array<number>;
  brandName?: string;
  sort?: SortValues;
};

const useItemsQuery = (
  variables: Variables,
  options?: UseQueryOptions<
    { results: Array<Item>; totalCount: number },
    QueryError,
    { results: Array<Item>; totalCount: number },
    QueryKey
  >
) => {
  const url = `${ENDPOINT}/marketplace/items`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [url, JSON.stringify(variables)];

  return useQuery<{ results: Array<Item>; totalCount: number }, QueryError>(
    cacheKey,
    async () => {
      const params: any = { ...variables };
      if (variables.categoryIds?.length) {
        params.categoryId = variables.categoryIds?.join(",") as any;
      }
      delete params.categoryIds;

      if (!params.brandName) {
        delete params.brandName;
      }

      const res = await fetchWithAuth<Result>(url, {
        params,
      });
      return res.response;
    },
    {
      ...options,
    }
  );
};
export default useItemsQuery;
