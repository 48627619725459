import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

export type Category = {
  id: number;
  name: string;
  icon: string;
  color: null | string;
  image: null | string;
};

type Result = {
  response: Array<Category>;
};
type QueryError = {};

const useCategoriesQuery = (
  options?: UseQueryOptions<
    Array<Category>,
    QueryError,
    Array<Category>,
    QueryKey
  >
) => {
  const url = `${ENDPOINT}/marketplace/categories`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [url];

  return useQuery<Array<Category>, QueryError>(
    cacheKey,
    async () => {
      const res = await fetchWithAuth<Result>(url);
      return res.response;
    },
    {
      ...options,
    }
  );
};
export default useCategoriesQuery;
