import Image from "next/image";
import cx from "classnames";
import Grid from "@/components/Grid";
import Typography from "@/components/Typography";
import { Item as ItemType } from "@/fetch/marketplace";
import styles from "./Item.module.scss";
import { useResponsive, useDialog, useFeatureFlags } from "@/hooks";
import { Skeleton } from "@/components/Loader";
import useFormatter from "./useFormatter";
import {
  ItemDialog,
  MobileItemDialog,
  LockDialog,
} from "@/components/Marketplace";

const Item: React.FC<{
  item?: ItemType;
  isLoading?: boolean;
  inList?: boolean;
}> = ({ item, isLoading, inList }) => {
  const { amountText, expiry } = useFormatter(item);
  const { isMobile } = useResponsive();
  const {
    flags: { gworldMarketplace },
  } = useFeatureFlags();

  const {
    onClose,
    isOpen: isDialogOpen,
    openDialog,
    query,
  } = useDialog("market-deal");
  const { isOpen: isOpenDialogLock, openDialog: openLockDialog } =
    useDialog("market-deal-locked");
  const itemId = parseInt(query.id as string);
  const isOpen = isDialogOpen && itemId == item?.id;
  const isOpenLock = isOpenDialogLock && itemId === item?.id;
  const onCloseDialog = () => {
    onClose({ id: itemId });
  };

  const openItem = () => {
    if (!item) return;

    if (gworldMarketplace) {
      openDialog({ id: item.id });
      return;
    }

    openLockDialog({ id: item.id });
  };

  return (
    <>
      <Grid item xs={12} md="auto">
        <div
          role="presentation"
          className={cx(styles.item, { [styles.listItem]: inList })}
          onClick={openItem}
        >
          {/*eslint-disable-next-line @next/next/no-img-element */}
          <img
            src={
              isMobile && inList
                ? "/images/mobile-deal.svg"
                : "/images/deal.svg"
            }
            alt="background"
            className={cx(styles.backgroundImage, {
              [styles.listImage]: inList,
            })}
          />

          <div className={cx(styles.logo, { [styles.listLogo]: inList })}>
            {isLoading ? (
              <Skeleton variant="rectangular" height="100%" />
            ) : (
              item && (
                <Image
                  objectFit="contain"
                  layout="fill"
                  src={item.brand_image}
                  alt=""
                />
              )
            )}
          </div>
          {isLoading ? (
            <div className={styles.skeleton}>
              <Skeleton width="70px" />
              <Skeleton width="60px" />
              <Skeleton width="100px" />
            </div>
          ) : (
            <div className={styles.topContainer}>
              <Typography>{item?.brand_name}</Typography>
              <Typography className={styles.categoryName}>
                {item?.category_name}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                className={styles.description}
              >
                {item?.short_description}
              </Typography>
            </div>
          )}

          <div
            className={cx(styles.bottomContainer, {
              [styles.bottomWithExpiry]: !!expiry,
              [styles.bottomInList]: inList,
              [styles.inListWithExpiry]: inList && !!expiry,
              [styles.bottomInList]: item?.physical_good && !expiry,
              [styles.inListWithPhysicalGood]:
                item?.physical_good && inList && !expiry,
            })}
          >
            {isLoading ? (
              <Skeleton width="70px" />
            ) : (
              <div className={styles.amountContainer}>
                <Typography
                  variant={item?.physical_good ? "body2" : "h4"}
                  className={styles.amountText}
                >
                  {amountText}
                </Typography>
                {Boolean(
                  item &&
                    item.type_percentage &&
                    !item.physical_good &&
                    !item.currency
                ) && (
                  <Typography variant="h5" className={styles.offText}>
                    off
                  </Typography>
                )}
              </div>
            )}

            <Typography
              variant="caption"
              color="text.secondary"
              className={styles.expiryText}
            >
              {expiry ? `Expires In ${expiry}` : ""}
            </Typography>
          </div>
        </div>
      </Grid>
      {isOpen && (
        <>
          {isMobile ? (
            <MobileItemDialog
              item={item}
              onClose={onCloseDialog}
              open={isOpen}
            />
          ) : (
            <ItemDialog item={item} onClose={onCloseDialog} open={isOpen} />
          )}
        </>
      )}
      {isOpenLock && <LockDialog />}
    </>
  );
};

export default Item;
