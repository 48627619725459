import Dialog, { DialogTitle, DialogContent } from "@/components/Dialog";
import { Item } from "@/fetch/marketplace";
import styles from "./ItemDialog.module.scss";
import Typography from "@/components/Typography";
import Button, { IconButton } from "@/components/Button";
import { CloseIcon } from "@/components/Icon";
import Image from "next/image";
import useFormatter from "./useFormatter";
import { useOpenExternalURL, useTrackers } from "@/hooks";

const ItemDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  item: Item | undefined;
}> = ({ open, onClose, item }) => {
  const { amountText, expiry } = useFormatter(item);
  const openURL = useOpenExternalURL();
  const { track } = useTrackers();

  if (!item) return null;

  const claim = () => {
    track("Deal Claimed", {
      eventId: "deal-claimed",
      ...item,
      itemId: item?.id || null,
    });
    openURL(item.link.trim());
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ classes: { root: styles.dialogPaper } }}
    >
      {/*eslint-disable-next-line @next/next/no-img-element */}
      <img
        src="/images/deal-dialog.svg"
        alt="background"
        className={styles.backgroundImage}
      />

      <DialogContent className={styles.content}>
        <IconButton onClick={onClose} className={styles.closeButton}>
          <CloseIcon />
        </IconButton>
        <div className={styles.logo}>
          <Image
            objectFit="contain"
            layout="fill"
            src={item.brand_image}
            alt=""
          />
        </div>
        <div className={styles.details}>
          <Typography variant="h5" className={styles.brand}>
            {item.brand_name}
          </Typography>
          <Typography className={styles.categoryName}>
            {item?.category_name}
          </Typography>
          <Typography
            component="div"
            variant="body2"
            className={styles.description}
          >
            <div
              dangerouslySetInnerHTML={{ __html: item.long_description }}
            ></div>
          </Typography>
        </div>
        <div className={styles.price}>
          <div className={styles.topPrice}>
            {item.is_upto === 1 && (
              <Typography className={styles.upTo}>Up to</Typography>
            )}
            <Typography
              component="span"
              variant={item.physical_good ? "h6" : "h4"}
            >
              {amountText}
            </Typography>
            {Boolean(
              item.type_percentage && !item.physical_good && !item.currency
            ) && (
              <Typography variant="h5" className={styles.offText}>
                off
              </Typography>
            )}
          </div>
          <Typography
            component="span"
            // color="text.secondary"
            className={styles.expiry}
            variant="body2"
          >
            {expiry && `Expires in ${expiry}`}
          </Typography>
          <Button className={styles.claimButton} onClick={claim}>
            Claim deal
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ItemDialog;
