import { useFormatCurrency } from "@/hooks";
import { Item } from "@/fetch/marketplace";
import dayjs from "dayjs";

const useFormatter = (item: Item | undefined) => {
  const { format } = useFormatCurrency();
  if (!item) return {};

  let amountText = "";

  if (item.currency) {
    amountText = format(item.amount, item.currency);
  } else if (item.physical_good) {
    amountText = item.physical_good;
  } else if (item.type_percentage) {
    amountText = `${item.amount || 0}%`;
  }

  const startDate = dayjs();
  const endDate = dayjs(item.expiry_date * 1000);
  let expiry: any = endDate.diff(startDate, "month");

  if (!item.expiry_date) {
    expiry = null;
  } else if (expiry >= 1) {
    expiry = `${expiry} ${expiry === 1 ? "month" : "months"}`;
  } else {
    expiry = endDate.diff(startDate, "day");
    expiry = `${expiry} ${expiry === 1 ? "day" : "days"}`;
  }

  return { amountText, expiry };
};

export default useFormatter;
