import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from "react-query";
import { ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

export type Item = {
  id: number;
  title: string;
  short_description: string;
  category_id: number;
  expiry_date: number;
  long_description: string;
  link: string;
  brand_id: number;
  _ca: number;
  _cb: number;
  _lma: number;
  _lmb: number;
  amount: number;
  currency: null;
  type_percentage: number;
  status: number;
  physical_good: null;
  is_upto: number;
  category_name: string;
  category_color: null;
  brand_name: string;
  brand_image: string;
  brand_location: string;
  office: null;
  category_icon: string;
};

type Result = {
  response: {
    results: Array<Item>;
    totalCount: number;
  };
};

type QueryError = {};

export enum SortValues {
  NewestDeals = "newestDeals",
  OldestDeals = "oldestDeals",
  BrandAZ = "brandAZ",
  BrandZA = "brandZA",
  Popularity = "popularity",
}

type Variables = {
  pageSize: number;
  pageNum: number;
  region?: string;
  categoryIds?: Array<number>;
  brandName?: string;
  sort?: SortValues;
};

export type PaginatedItemsQueryResult = {
  results?: Array<Item>;
  totalCount?: number;
};

export type PaginatedItemsFetchResult = {
  response: PaginatedItemsQueryResult;
  statusCode: number;
  success: boolean | string;
};

const usePaginatedItemsQuery = (
  variables: Variables,
  options?: UseInfiniteQueryOptions<
    PaginatedItemsQueryResult,
    any,
    PaginatedItemsQueryResult,
    PaginatedItemsQueryResult,
    QueryKey
  >
) => {
  const { fetchWithAuth } = useFetchWithAuth();

  const url = `${ENDPOINT}/marketplace/items`;
  const cacheKey = [url, "paginated", ...Object.entries(variables)];

  const queryResult = useInfiniteQuery<PaginatedItemsQueryResult, QueryError>(
    cacheKey,
    async ({ pageParam = 1 }) => {
      const params: any = { ...variables };
      if (variables.categoryIds?.length) {
        params.categoryId = variables.categoryIds?.join(",") as any;
      }
      delete params.categoryIds;

      if (!params.brandName) {
        delete params.brandName;
      }
      const result = await fetchWithAuth<PaginatedItemsFetchResult>(url, {
        params: { ...params, pageNum: pageParam },
      });
      return result.response;
    },
    {
      ...options,
      getNextPageParam: (lastPage, pages) => {
        if ((lastPage?.totalCount || 0) >= variables.pageSize * pages.length)
          return pages?.length + 1;
        return undefined;
      },
    }
  );
  return { ...queryResult, isLoading: queryResult.isLoading };
};
export default usePaginatedItemsQuery;
